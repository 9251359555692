/** @jsx jsx */
import PropTypes from 'prop-types'
import React, { useState, Fragment } from 'react'
import { Button, Flex, Text, Spinner, jsx } from 'theme-ui'
import {
  useCart,
  useTranslate,
  utils
} from '@chordcommerce/gatsby-theme-autonomy'

const windowGlobal = typeof window !== 'undefined' && window

const ProductAddToCartButton = ({
  soldOut = false,
  comingSoon = false,
  disabled = false,
  price,
  quantity,
  regularPrice,
  sku,
  handleAddToCart,
  isKit,
  subscribed,
  interval,
  showRegularPrice,
  ...props
}) => {
  const translate = useTranslate()
  const { addToCart, subscribeProduct } = useCart()
  const { toUsdCurrency } = utils
  const [buttonIsLoading, setButtonIsLoading] = useState(false)
  const [apiError, setApiError] = useState(null)

  const handleSubmit = async event => {
    if (event) event.preventDefault()
    if (disabled || comingSoon || soldOut) return

    setButtonIsLoading(true)
    setApiError(null)

    if(subscribed)
    {
    	try {
	      // End date is optional. Without an end date, the subscription runs for ever.
	      // Passing it here just so you can see that it can be specified.
	      const endDate = {}

	      await subscribeProduct({ sku, quantity, interval, endDate })
	    } catch (error) {
	      setApiError(translate('error.api.default'))
	    }
    }
    
    try {
      if(isKit)
      {
        windowGlobal && localStorage.setItem('ContainsKit', JSON.stringify({contains: true, sku: sku}))
        //set kit variable in order to show the Terms of Agreement on checkout
      }
      await addToCart(sku, quantity)
      
    } catch (error) {
      setApiError(translate('error.api.default'))
    }

    setButtonIsLoading(false)
  }

	return(
	  <>
	  	{
	  		buttonIsLoading ? 
	  		<Spinner data-testid="spinner" size="15" color="inherit" />
	  		:
	  		<>
	  		 	{ apiError ?
			    	<Text color="errorDark" variant="textLink" mt="1.5rem">
			      		{apiError.toString()}
			    	</Text>
					:
					<div className="col-8 d-flex flex-row mt-4 mt-md-0" style={{pointerEvents: buttonIsLoading ? 'none' : 'auto'}} onClick={(e) => handleSubmit(e)}>
						<div className="darkBlueBg SFPRO18B addToBag w-100 py-2 d-flex align-items-center justify-content-center">
					    	{translate('product.add_to_bag')}
					    </div>
					    {
                showRegularPrice &&
                  <div className="darkGreyBg SFPRO22B border p-3 d-flex align-items-center justify-content-center">
                    ${regularPrice}
                  </div>
              }
					</div>
				}
	  		</>
	  	}
	  </>	
  	);
}

ProductAddToCartButton.propTypes = {
  soldOut: PropTypes.bool,
  comingSoon: PropTypes.bool,
  disabled: PropTypes.bool,
  price: PropTypes.number,
  quantity: PropTypes.number.isRequired,
  regularPrice: PropTypes.number,
  sku: PropTypes.string.isRequired
}

export default ProductAddToCartButton
