import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useCart } from '@chordcommerce/gatsby-theme-autonomy'
import { Spinner } from 'theme-ui'
import Dropdown from 'react-bootstrap/Dropdown'

const windowGlobal = typeof window !== 'undefined' && window

/****
the subscription selected interval in the Product Details page comes from Contentful and looks like the following:
{
    "id": "bc0f5e65-ac5f-5251-aa9f-8ed23ef16d5f",
    "name": "Every 1 month",
    "slug": "one-month-subscription-refill",
    "presentation": "Our recommended subscription for your the CBD you rely on. Refills every month.",
    "length": 1,
    "unit": [
        "month"
    ]
}

whereas the subscription selected interval in the Cart page comes from the Chord cart data and looks like the following:
{
    "id": 28,
    "intervalLength": 2,
    "intervalUnits": "month",
    "interval": "2 months",
    "endDate": null,
    "quantity": 1,
    "sku": "30201011",
    "productSlug": "anytime-gummies"
}
****/

const SubscriptionSelector = ({ handleChange, selectedInterval, subscription, isCart, ...props }) => {
  const handleSub = interval => {
    if (handleChange) handleChange(interval)
  }
  return (
    <>
      {props.isLoading && <Spinner color="inherit" />}

      {!props.isLoading && (
        <Dropdown className="mt-3">
          <Dropdown.Toggle className="darkFont px-4 bg-white rounded border-yellow-30" id="dropdown-product-types">
            {isCart ? `Every ${selectedInterval?.interval}` : selectedInterval?.name}
          </Dropdown.Toggle>

          <Dropdown.Menu className="shopDropdownMenu">
            {subscription?.intervals &&
              subscription?.intervals.length > 0 &&
              subscription?.intervals.map((interval, i) => (
                <Dropdown.Item
                  key={i}
                  className="d-flex align-items-center justify-content-center shopDropdownMenuItem darkFont"
                  onClick={() => handleSub(interval)}
                >
                  {interval.name}
                </Dropdown.Item>
              ))}
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  )
}

const CartSubscriptionSelector = ({
  subscription,
  selectedInterval,
  setSelectedInterval,
  sku,
  isCart,
  isSKUinCart,
  ...props
}) => {
  const { subscribeProduct, removeFromCart } = useCart()
  const [isLoading, setIsLoading] = useState(false)

  const updateSubscription = async interval => {
    const qtn = 1
    setSelectedInterval(interval)
    setIsLoading(true)

    try {
      // End date is optional. Without an end date, the subscription runs for ever.
      // Passing it here just so you can see that it can be specified.
      const endDate = {}
      let newInterval = {}
      newInterval.unit = ['month']
      newInterval.length = interval?.length
      newInterval.name = interval?.name

      let productInCart = isSKUinCart(sku)

      /* Removal from the cart is needed because the subscribeProduct function adds a product 
      to the cart with the given subscription each time it's called.
      The updateSubscription sdk function requires both billing & shipping address and it's impossible to 
      have them in the Product Details Page and the first steps of the Checkout for a first time, 
      unauthenticated user.  
      */
      if (productInCart) await removeFromCart(productInCart)

      await subscribeProduct({ sku, quantity: qtn, interval: newInterval, endDate })
    } catch (error) {
      console.error(error)
    }

    setIsLoading(false)
  }

  useEffect(() => setIsLoading(false), [subscription, sku])

  return (
    <SubscriptionSelector
      isLoading={isLoading}
      handleChange={updateSubscription}
      subscription={subscription}
      selectedInterval={selectedInterval}
      setSelectedInterval={setSelectedInterval}
      isCart={isCart}
      {...props}
    />
  )
}

export default CartSubscriptionSelector
