import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'
import ProductPage from '~/components/Product/Page'
import { setAffiliateCode } from '~/utils/affiliates'
import withLocation from '~/hoc/withLocation'

const ProductTemplate = ({ data, search }) => {
  setAffiliateCode(search)
  const { product } = data

  //TODO retrieve the logged in user role
  return (
    <Layout>
      <Metadata title={product.name} description={product.shortDescription} />
      <ProductPage product={product} role="" />
    </Layout>
  )
}

export const query = graphql`
  query ProductQuery($slug: String, $locale: String) {
    product: contentfulProduct(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      ...ProductPageFragment
    }
  }
`

export default withLocation(ProductTemplate)
