/** @jsx jsx */
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Box, Flex, Heading, jsx } from 'theme-ui'
import ProductAddToCartButton from '~/components/Product/AddToCartButtonNew'
import ProductOutOfStock from '~/components/Product/OutOfStock'
import useProductVariants from '~/hooks/components/use-product-variants'
import { BLOCKS, MARKS } from '@contentful/rich-text-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import Form from 'react-bootstrap/Form'
import { useTranslate, useCart } from '@chordcommerce/gatsby-theme-autonomy'
import SubscriptionSelector from '~/components/Cart/SubscriptionSelector'
import CONFIG from '~/config'

const windowGlobal = typeof window !== 'undefined' && window
const AMBASSADOR = CONFIG.ROLES.ambassador

const Bold = ({ children }) => <span className="fw-bold">{children}</span>
const Text = ({ children }) => <p className="align-center">{children}</p>

const options = {
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    [BLOCKS.EMBEDDED_ASSET]: node => {
      return (
        <>
          <h2>Embedded Asset</h2>
          <pre>
            <code>{JSON.stringify(node, null, 2)}</code>
          </pre>
        </>
      )
    }
  },
  renderText: text => {
    return text.split('\n').reduce((children, textSegment, index) => {
      return [...children, index > 0 && <br key={index} />, textSegment]
    }, [])
  }
}

const ProductDetails = ({
  product,
  role,
  becomeAmbassador,
  collectionName = null,
  handleVariantChange,
  availability,
  ...props
}) => {
  const { description, variants, optionTypes, subscription, subscriptionForAmbassadors, klaviyoListId } = product
  const translate = useTranslate()
  const [intervalItem, setIntervalItem] = useState('')
  const [subscribeTo, setSubscribeTo] = useState(false)
  const [selectedInterval, setSelectedInterval] = useState(null)

  const [selectedQuantity, setSelectedQuantity] = useState(1)

  const { currentVariant, selectVariant } = useProductVariants(variants)

  // get all the available choices from the variants
  const allVariantOptions = variants.flatMap(v => v.optionValues)

  const uniqueVariantOptions = allVariantOptions.reduce((acc, option = {}) => {
    return acc.find(o => o.slug === option.slug) ? acc : [...acc, option]
  }, [])

  // build a list of option types, restricting choices to those available via our variants
  const allowedOptionValues = optionTypes
    ? optionTypes.map(optionType => ({
        name: optionType.name,
        optionValues: uniqueVariantOptions.reduce((acc, option = {}) => {
          return option.option_type[0].slug === optionType.slug ? [...acc, option] : acc
        }, []),
        presentation: optionType.presentation,
        slug: optionType.slug
      }))
    : []

  const { sku, name, size, comingSoon, soldOut, price, regularPrice } = currentVariant

  const handleIntervalChange = interval => {
    setIntervalItem(interval.name)
    setSelectedInterval(interval)
  }

  //current implementation using soldOut is not correct, it can show soldOut = false, yet there can be no products in stock
  //soldOut = false is the default setting and I see no direct way to change it from the Chord Panel product settings, it's value is probably changed when the "Available On" and "Discontinue On" date pickers are used.

  let signUpForAmbassador = false //this needs to be set on the ambassador sign up page

  if (windowGlobal) {
    signUpForAmbassador = localStorage.getItem('signUpForAmbassador')
    if (becomeAmbassador && signUpForAmbassador) localStorage.setItem('becomeAmbassador', true)
  }

  const { cart, loadCart } = useCart()

  useEffect(() => {
    handleVariantChange(currentVariant)
  }, [currentVariant, handleVariantChange])

  useEffect(() => {
    const loadCartData = async () => {
      try {
        await loadCart()
      } catch (error) {
        console.error(error)
      }
    }

    loadCartData()
  }, [loadCart])

  const isSKUinCart = sku => {
    return cart?.data?.lineItems?.find(cartItem => cartItem.variant?.sku === sku).id
  }

  return (
    <div>
      <Heading as="h1" variant="h2">
        {name}
      </Heading>
      <Heading
        as="h6"
        variant="navLink"
        sx={{
          mt: ['8px', null, '12px'],
          mb: ['8px', null, '20px']
        }}
      >
        {size}
      </Heading>

      {availability && (
        <div className="border p-3">
          <div className="d-flex flex-column">
            <Form>
              {role && role === AMBASSADOR && subscriptionForAmbassadors ? (
                <Form.Check type="radio" id="subscribe">
                  <Form.Check.Input type="radio" checked={subscribeTo} onChange={() => setSubscribeTo(!subscribeTo)} />
                  <Form.Check.Label className="OSR20 darkFont ms-3">
                    Subscribe and save {subscriptionForAmbassadors.discountPercentage}%
                  </Form.Check.Label>
                </Form.Check>
              ) : (
                <Form.Check type="radio" id="subscribe">
                  <Form.Check.Input type="radio" checked={subscribeTo} onChange={() => setSubscribeTo(!subscribeTo)} />
                  <Form.Check.Label className="OSR20 darkFont ms-3">
                    {translate('product.subscribe_and_save')} {subscription?.discountPercentage}%
                  </Form.Check.Label>
                </Form.Check>
              )}

              <Form.Check type="radio" id="one-time">
                <Form.Check.Input type="radio" checked={!subscribeTo} onChange={() => setSubscribeTo(!subscribeTo)} />
                <Form.Check.Label className="OSR20 darkFont ms-3">
                  {translate('product.one_time_purchase')}
                </Form.Check.Label>
              </Form.Check>
            </Form>
          </div>
          <div className="d-flex flex-column-reverse flex-md-row mt-4 align-items-start align-items-md-center">
            <ProductAddToCartButton
              soldOut={soldOut}
              comingSoon={comingSoon}
              price={price}
              quantity={selectedQuantity}
              regularPrice={regularPrice}
              sku={sku}
              subscribed={subscribeTo}
              interval={selectedInterval || (subscription?.intervals && subscription.intervals[0])}
              showRegularPrice={true}
            />
            <div className="col-4 ms-5 mt-4 mt-md-0">
              {subscribeTo && subscription && (
                <>
                  <SubscriptionSelector
                    sku={sku}
                    selectedInterval={selectedInterval || subscription.intervals[0]}
                    setSelectedInterval={setSelectedInterval}
                    subscription={subscription}
                    isSKUinCart={isSKUinCart}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      )}

      {!availability && <ProductOutOfStock sku={sku} listId={klaviyoListId} />}

      {description && <div className="mt-4">{renderRichText(description, options)}</div>}
    </div>
  )
}

ProductDetails.propTypes = {
  product: PropTypes.shape({
    soldOut: PropTypes.bool,
    comingSoon: PropTypes.bool,
    description: PropTypes.shape({}),
    name: PropTypes.string,
    price: PropTypes.number,
    regularPrice: PropTypes.number,
    relatedProducts: PropTypes.array,
    size: PropTypes.string,
    sku: PropTypes.string,
    quote: PropTypes.shape({}),
    collectionName: PropTypes.string
  }),
  handleVariantChange: PropTypes.func.isRequired
}

export default ProductDetails
