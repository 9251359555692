/** @jsx jsx */
import React, { Fragment, useState, useEffect } from 'react'
import { Box, Container, Flex, jsx } from 'theme-ui'
import ImageCarousel from '~/components/Generic/ImageCarousel'
import RelatedProducts from '~/components/Generic/RelatedProducts'
import ProductDetails from '~/components/Product/Details'
import ProductImages from '~/components/Product/Images'
import ProductQuote from '~/components/Product/Quote'
import ChordService from '~/api/chord-services'
import { FaSistrix } from 'react-icons/fa'
import { Link } from 'gatsby'
import { IoIosStar, IoIosStarOutline } from 'react-icons/io'
import Helmet from 'react-helmet'

const ProductPage = ({ product, role, becomeAmbassador }) => {
  const { relatedProducts, quote, variants, sku } = product
  const [inStock, setInStock] = useState(false)
  const [allProducts, setAllProducts] = useState([])

  // each product have at least one variant (enforced by Contentful model)
  const [variant, setVariant] = useState(variants[0])

  // get images variant if any otherwise fallback on product images
  const images = variant.images.length > 0 ? variant.images : product.images

  const collection = product.collection.find(collection => collection)
  const curUrl = typeof window !== 'undefined' ? window.location.href : ''

  const setStock = allProducts => {
    if (allProducts && allProducts.data?.products.length > 0) {
      const prods = allProducts?.data?.products

      if (sku) {
        for (let i = 0; i < prods.length; i++) {
          if (prods[i].master?.sku === sku) {
            setInStock(prods[i].master?.in_stock)
          }
        }
      } else {
        console.log('no sku found')
      }
    }
  }

  useEffect(() => {
    function fetchProducts() {
      ChordService.allProducts()
        .then(products => setStock(products))
        .catch(() => {
          console.error('error fetching products')
        })
    }

    fetchProducts()
  }, [])

  useEffect(() => {
    const scriptTag = document.createElement('script')
    scriptTag.src = `https://staticw2.yotpo.com/${process.env.GATSBY_YOTPO_APP_KEY}/widget.js`
    document.head.appendChild(scriptTag)
  })

  const Reviews = () => (
    <>
      <div className="yotpo bottomLine" data-yotpo-product-id={variant?.sku}></div>
      <div
        className="yotpo yotpo-main-widget"
        data-appkey={process.env.GATSBY_YOTPO_APP_KEY}
        data-product-id={variant?.sku}
        data-price={variant?.regularPrice}
        data-currency="USD"
        data-name={variant?.name}
        data-url={curUrl}
        data-image-url={variant?.mainImage?.gatsbyImageData?.images?.fallback?.src}
      ></div>
    </>
  )

  return (
    <>
      <div className="container" style={{ marginTop: '100px' }}>
        <div className="shopCommons">Shop Commons</div>
        <div className="SFPRO18B blueFont mt-3">
          <Link to="/shop">◂ BACK TO PRODUCT LIST</Link>
        </div>
      </div>
      <div className="container mt-4">
        <Flex
          sx={{
            flexDirection: ['column', 'column', 'row'],
            marginTop: ['0', null, '32px']
          }}
        >
          <Box
            sx={{
              variant: 'responsive.mobileTablet',
              width: ['92%', '60%'],
              margin: 'auto',
              '& .carousel-slider': {
                overflow: 'visible'
              },
              '& .slider-wrapper': {
                overflow: 'visible'
              }
            }}
          >
            <ImageCarousel images={images} />
            <Reviews />
          </Box>

          <Box sx={{ variant: 'responsive.desktop', width: '100%' }}>
            <ProductImages images={images} />
            <Reviews />
          </Box>

          <ProductDetails
            product={product}
            role={role}
            becomeAmbassador={becomeAmbassador}
            collectionName={collection.slug}
            sx={{
              maxWidth: ['100%', '100%', '50%'],
              paddingLeft: ['1rem', null, '2rem'],
              paddingRight: ['1rem', null, '2rem']
            }}
            handleVariantChange={variant => setVariant(variant)}
            availability={sku && inStock}
          />
        </Flex>
      </div>
      <Container variant="fullWidth">{quote && <ProductQuote quote={quote} />}</Container>
      {/*<Container>
        {relatedProducts && <RelatedProducts products={relatedProducts} />}
      </Container>*/}
    </>
  )
}

export default ProductPage
