/** @jsx jsx */
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { Box, Grid, jsx } from 'theme-ui'
import Thumbnail from '~/components/Generic/Thumbnail'

const ProductImages = ({ images }) => {
  const [selectedImage, setSelectedImage] = useState(images[0])

  useEffect(() => {
    setSelectedImage(images[0])
  }, [images])

  const image = getImage(selectedImage)

  return (
    <Box sx={{ position: 'relative' }}>
      <Box
        mb={2}
        sx={{
          marginLeft: 'calc(10% + 12px)',
          marginRight: '40px'
        }}
      >
      <GatsbyImage image={image} alt={selectedImage.title} />
      </Box>
      <Grid
        gap={'12px'}
        columns={1}
        sx={{
          width: '10%',
          position: 'absolute',
          top: '0',
          left: '0'
        }}
      >
        {images.map((image, index) => (
          <Thumbnail
            key={index}
            image={image}
            handleClick={image => setSelectedImage(image)}
            selected={selectedImage.id === image.id}
          />
        ))}
      </Grid>
    </Box>
  )
}

ProductImages.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      fluid: PropTypes.shape()
    })
  ).isRequired
}

export default ProductImages
