/** @jsx jsx */
import PropTypes from 'prop-types'
import { Fragment, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Box, Button, Flex, jsx, Spinner, Text } from 'theme-ui'
import FormInputWithLabel from '~/components/Generic/Form/InputWithLabel'
import {
  logSentryError,
  useAnalytics,
  useTranslate
} from '@chordcommerce/gatsby-theme-autonomy'
import UserService from '~/api/services'

const ProductOutOfStock = ({ sku, listId }) => {
  const translate = useTranslate()
  const { trackCreateStockRequest } = useAnalytics()
  const [loading, setLoading] = useState(null)
  const [submitted, setSubmitted] = useState(false)

  const { register, handleSubmit, errors } = useForm()

  const onSubmit = async data => {
    setLoading(true)
    try {
      await trackCreateStockRequest(data.email, sku)
      
      /*let lists = await UserService.getAllKlaviyoLists()

      if(lists?.data?.data)
      {
        //check if there is an existing list containing in its name the sku of the product
  
        let found = lists.data.data.find(list => list.list_name.includes(sku))
        if(found)
        {
          //await UserService.subscribeToNotificationList(data.email)
        }
        else {
          //create a new Klaviyo list for the product
          //await UserService.createNotificationList(`Product notification list for ${sku}`)
        }
      }*/

      await UserService.subscribeToNotificationList(data.email, listId)

      setSubmitted(true)

    } catch (error) {
      logSentryError(error, { source: 'ProductOutOfStock', sku })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Flex sx={{ flexDirection: 'column' }}>
      {!submitted && (
        <Fragment>
          <Box
            sx={{
              textAlign: 'center',
              padding: '2rem',
              marginBottom: '1rem'
            }}
          >
            <Text variant="text.body">
              {translate('product.notify_when_available')}
            </Text>
          </Box>

          <Box as="form" data-testid="form" onSubmit={handleSubmit(onSubmit)}>
            <Flex
              sx={{
                flexDirection: ['column', 'row'],
                justifyContent: 'space-between'
              }}
            >
              <Box
                sx={{
                  marginRight: [0, '1rem'],
                  marginBottom: '1rem',
                  width: ['100%', '80%']
                }}
              >
                <FormInputWithLabel
                  sx={{ backgroundColor: 'white' }}
                  title={translate('your_email')}
                  label={translate('your_email')}
                  aria-label={translate('your_email')}
                  placeholder={translate('your_email')}
                  {...register('email', {
                    required: true,
                    pattern: {
                      value: /^\S+@\S+$/i,
                      message: translate('validation.invalid_email')
                    }
                  })}
                />
              </Box>
              <Button>
                {loading && <Spinner size="15" color="inherit" />}
                {!loading && <Text>{translate('product.notify_me')}</Text>}
              </Button>
            </Flex>
            {errors &&
              errors['email'] &&
              errors['email'].type === 'required' && (
                <Text color="errorDark">
                  {translate('validation.required_field')}
                </Text>
              )}
            {errors &&
              errors['email'] &&
              errors['email'].type === 'pattern' && (
                <Text color="errorDark">
                  {translate('validation.invalid_email')}
                </Text>
              )}
          </Box>
        </Fragment>
      )}

      {submitted && (
        <Box
          sx={{
            textAlign: 'center',
            padding: '2rem',
            marginBottom: '1rem'
          }}
        >
          <Text variant="text.body">
            {translate('product.notify_when_available_thank_you')}
          </Text>
        </Box>
      )}
    </Flex>
  )
}

ProductOutOfStock.propTypes = {
  sku: PropTypes.string.isRequired
}

export default ProductOutOfStock
