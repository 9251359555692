import PropTypes from "prop-types"

const windowGlobal = typeof window !== 'undefined' && window

const setAffiliateCode = (search, location) => {
  if(search)
  {
  	const { utm_campaign } = search
  	utm_campaign && windowGlobal && localStorage.setItem('affiliateCode', utm_campaign)
  }
}

setAffiliateCode.propTypes = {
  search: PropTypes.object,
  location: PropTypes.object,
}

export {setAffiliateCode}