/** @jsx jsx */
import { jsx } from 'theme-ui'
import PropTypes from 'prop-types'
import { useAnalytics } from '@chordcommerce/gatsby-theme-autonomy'

const windowGlobal = typeof window !== 'undefined' && window

const ProductLink = ({ slug, children, sx, ...props }) => {
  const { trackClickProduct } = useAnalytics()

  const url = `/products/${slug}/`
  const navigate = url => {
    trackClickProduct(slug)
    windowGlobal && windowGlobal.open(url, '_self')
  }

  return (
    <div
      onClick={() => navigate(url)}
      sx={{
        ...sx
      }}
      {...props}
    >
      {children}
    </div>
  )
}

ProductLink.propTypes = {
  slug: PropTypes.string.isRequired,
  children: PropTypes.node
}

export default ProductLink
